import React from 'react';
import { Worker, Viewer, SpecialZoomLevel } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';

const Tac = () => {
    const pdfUrl = '/assets/Documents/TermsAndConditions.pdf';

    return (
        <div className="flex flex-col items-center p-4">
            <h1 className="text-2xl md:text-3xl lg:text-4xl font-bold mb-6 text-center">
                Terms and Conditions
            </h1>
            <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
                <div className="w-full flex-1 overflow-hidden">
                    <div
                        className="relative"
                        style={{
                            width: '100%',
                            height: '80vh', // Use a percentage-based height for responsiveness
                            overflow: 'hidden',
                        }}
                    >
                        <Viewer
                            fileUrl={pdfUrl}
                            defaultScale={SpecialZoomLevel.PageWidth} // Ensures the PDF width fits the screen
                        />
                    </div>
                </div>
            </Worker>
        </div>
    );
};

export default Tac;
