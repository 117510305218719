// CoinList.js
import React, { useState, useEffect, useRef, useContext } from "react";
import { Sparklines, SparklinesLine } from "react-sparklines";
import { MagnifyingGlassIcon, AdjustmentsHorizontalIcon } from "@heroicons/react/24/solid";
import { useNavigate } from "react-router-dom";
import Spinner from "./Spinner"; // Ensure the path is correct
import { CoinsContext } from "../contexts/CoinsContext";
import Countdown from "./Countdown";

// Utility function to format prices with commas
const formatPrice = (price) => {
  if (price === null || price === undefined) return null; // Return null instead of 'N/A'
  return `$${parseFloat(price).toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })}`;
};

const CoinList = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate();
  const { coinsData, isDataFetched, error } = useContext(CoinsContext);
  const [showFilters, setShowFilters] = useState(false);
  const [activeFilter, setActiveFilter] = useState('all'); // 'all', 'closing-soon', 'new-rooms', 'featured'

  // Determine line color based on price trend
  const getLineColor = (trend) => {
    if (trend && trend.length > 0) {
      return trend[trend.length - 1] >= trend[0] ? "#00ff66" : "#e13131"; // Green or Red
    }
    return "gray";
  };

  // Calculate percentage change
  const calculatePercentageChange = (currentPrice, initialPrice) => {
    if (initialPrice === null || initialPrice === 0) return null;
    const change = ((currentPrice - initialPrice) / initialPrice) * 100;
    return change.toFixed(2); // Two decimal places
  };

  // Handle click on a coin card
  const handleCoinClick = (symbol, id) => {
    const baseSymbol = symbol.toLowerCase(); // e.g., 'DOGE' from 'DOGEUSDT'
    navigate(`/event/${baseSymbol}/${id}`);
  };

  // Add this filter function
  const getFilteredCoins = (coins) => {
    // Get current time in GMT/UTC
    const currentTime = new Date().getTime() + new Date().getTimezoneOffset() * 60000;
    
    switch (activeFilter) {
      case 'featured':
        return coins.filter(coin => coin.is_featured === 1);
      
      case 'closing-soon':
        return coins.filter(coin => {
          // Convert end_time to GMT timestamp
          const endTime = new Date(coin.end_time).getTime();
          const timeLeft = endTime - currentTime;
          console.log("Filtering closing soon:", {
            endTime: new Date(endTime).toISOString(),
            currentTime: new Date(currentTime).toISOString(),
            timeLeft: timeLeft / (1000 * 60 * 60) + ' hours' // Convert to hours for logging
          });
          return timeLeft > 0 && timeLeft <= 3600000; // 1 hour in milliseconds
        });
      
      case 'new-rooms':
        return coins.filter(coin => {
          // Convert start_time to GMT timestamp
          const startTime = new Date(coin.start_time).getTime();
          const timeSinceCreation = currentTime - startTime;
          console.log("Filtering new rooms:", {
            startTime: new Date(startTime).toISOString(),
            currentTime: new Date(currentTime).toISOString(),
            timeSinceCreation: timeSinceCreation / (1000 * 60 * 60) + ' hours' // Convert to hours for logging
          });
          return timeSinceCreation <= 3600000; // Created within last hour
        });
      
      default:
        return coins;
    }
  };

  const filteredResults = getFilteredCoins(coinsData)
      .filter(coin => 
        coin.symbol.toLowerCase().includes(searchTerm.toLowerCase()) ||
        coin.symbol.slice(0, -4).toLowerCase().includes(searchTerm.toLowerCase())
      );

  return (
    <div className="flex flex-col items-center justify-center mt-5 text-white">
      {/* Search Input */}
      <div className="w-full relative">
        <div className="relative flex items-center justify-center mb-4">
          <MagnifyingGlassIcon className="absolute left-3 h-5 w-5 text-gray-200" />
          <input
            type="text"
            placeholder="Search markets"
            className="pl-10 pr-3 py-2 h-10 bg-transparent placeholder-white rounded-md w-full border-2 border-gray-700 focus:border-blue-500 focus:outline-none mx-auto"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <AdjustmentsHorizontalIcon 
            className="absolute right-3 h-5 w-5 text-gray-200 cursor-pointer hover:text-blue-500"
            onClick={() => setShowFilters(!showFilters)} 
          />
        </div>

        {/* Filter dropdown */}
        {showFilters && (
          <div className="absolute right-0 top-10 w-48 rounded-md shadow-lg bg-gray-800 ring-1 ring-black ring-opacity-5 z-50">
            <div role="menu" aria-orientation="vertical">
              <button
                className={`block px-4 py-2 rounded-t-md text-sm w-full text-left ${
                  activeFilter === 'all' ? 'text-blue-500 font-bold' : 'text-gray-200'
                } hover:bg-gray-700`}
                onClick={() => setActiveFilter('all')}
              >
                All Rooms
              </button>
              <button
                className={`block px-4 py-2 text-sm w-full text-left ${
                  activeFilter === 'featured' ? 'text-blue-500 font-bold' : 'text-gray-200'
                } hover:bg-gray-700`}
                onClick={() => setActiveFilter('featured')}
              >
                Featured Pools
              </button>
              <button
                className={`block px-4 py-2 text-sm w-full text-left ${
                  activeFilter === 'closing-soon' ? 'text-blue-500 font-bold' : 'text-gray-200'
                } hover:bg-gray-700`}
                onClick={() => setActiveFilter('closing-soon')}
              >
                Closing Soon
              </button>
              <button
                className={`block px-4 rounded-b-md py-2 text-sm w-full text-left ${
                  activeFilter === 'new-rooms' ? 'text-blue-500 font-bold' : 'text-gray-200'
                } hover:bg-gray-700`}
                onClick={() => setActiveFilter('new-rooms')}
              >
                New Rooms
              </button>
            </div>
          </div>
        )}

        {/* Error Message */}
        {error && (
          <div className="text-center mt-6 text-red-500">
            <p>{error}</p>
          </div>
        )}

        {/* Coin Grid */}
        {isDataFetched ? (
          filteredResults.length > 0 ? (
            <div className="grid grid-cols-5 gap-4">
              {/* Add blank columns at the start if needed */}
              {filteredResults.length < 5 && 
                [...Array(Math.floor((5 - filteredResults.length) / 2))].map((_, i) => (
                  <div key={`blank-start-${i}`} className="invisible" />
                ))
              }
              
              {/* Render actual coin cards */}
              {filteredResults.map((coin) => {
                //console.log("Rendering Coin:", coin); // Debug log
                const percentageChange = calculatePercentageChange(
                  coin.currentPrice,
                  coin.initialPrice
                );
                const isPositive =
                  percentageChange !== null &&
                  parseFloat(percentageChange) >= 0;

                return (
                  <div
                    key={coin.symbol}
                    className="relative bg-gradient-to-l from-gray-800 to-gray-700 rounded-lg shadow-md hover:shadow-xl transition duration-300 ease-in-out p-4 cursor-pointer flex flex-col justify-between"
                    onClick={() => handleCoinClick(coin.symbol, coin.id)}
                    role="button"
                    tabIndex={0}
                    onKeyPress={(e) => {
                      if (e.key === "Enter")
                        handleCoinClick(coin.symbol, coin.id);
                    }}
                    aria-label={`View details for ${coin.symbol.slice(0, -4)}`}
                  >
                    {/* Top Row: Exchange Name, Coin Name and Mini Chart */}
                    <div className="flex justify-between items-start">
                      {/* Exchange Name and Coin Name */}
                      <div className="flex flex-col">
                        <h3 className="text-md font-semibold text-gray-200">
                          {coin.symbol}
                        </h3>
                      </div>

                      {/* Mini Price Trend Chart */}
                      <div className="w-20 h-15 pl-3">
                        {coin.priceTrend == null ||
                        coin.priceTrend.length === 0 ? (
                          <Spinner />
                        ) : (
                          <Sparklines
                            data={coin.priceTrend}
                            width={80}
                            height={30}
                            margin={2}
                          >
                            <SparklinesLine
                              color={getLineColor(coin.priceTrend)}
                              style={{ strokeWidth: 2, fill: "none" }}
                            />
                          </Sparklines>
                        )}
                      </div>
                    </div>

                    {/* Current Price */}
                    <div className="mt-1">
                      <p className="text-sm font-bold">
                        {coin.currentPrice !== null &&
                        coin.currentPrice !== undefined ? (
                          `$${coin.currentPrice}`
                        ) : (
                          <Spinner />
                        )}
                      </p>
                    </div>

                    {/* Percentage Change */}
                    <div>
                      {percentageChange !== null ? (
                        <p
                          className={`text-xs font-semibold ${
                            isPositive ? "text-green-400" : "text-red-400"
                          }`}
                        >
                          {isPositive ? "+" : ""}
                          {percentageChange}%
                        </p>
                      ) : (
                        <Spinner />
                      )}
                    </div>
                    <div className="text-xs !font-thin !text-gray-400 mt-2">
                      <Countdown
                        targetDate={coin.end_time}
                        className="!text-xs !font-thin"
                      >
                        before the pool closes
                      </Countdown>
                    </div>
                  </div>
                );
              })}

              {/* Add blank columns at the end if needed */}
              {filteredResults.length < 5 && 
                [...Array(Math.ceil((5 - filteredResults.length) / 2))].map((_, i) => (
                  <div key={`blank-end-${i}`} className="invisible" />
                ))
              }
            </div>
          ) : (
            <div className="flex justify-center items-center">
              <p>No coins found</p>
            </div>
          )
        ) : (
          <div className="flex justify-center items-center">
            <Spinner />
          </div>
        )}
      </div>
    </div>
  );
};

export default CoinList;
