import React, { useEffect, useState } from 'react';
import { useParams, useSearchParams, useNavigate } from 'react-router-dom';

const PaymentStatus = () => {
    // Get the dynamic parameter from the route
    const { gateway } = useParams();
    const [searchParams] = useSearchParams();
    const status = searchParams.get('status'); // 'success'

    // State variables
    const [message, setMessage] = useState("Redirecting...")
    const [flag, setFlag] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        let newStatus = "";
        if( gateway && status ) {
            newStatus = status;
        } else {
            newStatus = "pending";
        }

        navigate(`/dashboard?status=${newStatus}&gateway=${gateway}`);
        
    }, [window.location.search]); // Run when these dependencies change

    useEffect(() => {
        const timeout = setTimeout(() => {
            if(! flag ) {
                setFlag(true); // Change the flag after 5 seconds

                let newStatus = "";
                if( gateway && status ) {
                    newStatus = status;
                } else {
                    newStatus = "pending";
                }

                navigate(`/dashboard?status=${newStatus}&gateway=${gateway}`);
            }
        }, 3000);

        // Cleanup the timeout when the component unmounts or if useEffect re-runs
        return () => clearTimeout(timeout);
    }, [window.location.search]); // Empty dependency array means this runs only once

    return (
        <div className="mt-[6rem] text-white text-center text-md">
            <p>{message}</p>
        </div>
    );
};

export default PaymentStatus;
