// NotificationBell.jsx
import React from 'react';
import { BellIcon } from '@heroicons/react/24/solid';

const NotificationBell = ({ notificationCount, onClick }) => {
  return (
    <div className="relative cursor-pointer" onClick={onClick}>
      <BellIcon className="h-6 w-6 text-white" />
      {notificationCount > 0 && (
        <span className="absolute top-0 right-0 inline-flex items-center justify-center px-1.5 py-0.5 text-xs font-bold leading-none text-white transform -translate-y-1/2 translate-x-1/2 bg-red-600 rounded-full animate-pulse-red">
          {notificationCount}
        </span>
      )}
    </div>
  );
};

export default NotificationBell;
