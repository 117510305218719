import React, { createContext, useContext } from "react";

const ApiContext = createContext();

export const useApi = () => useContext(ApiContext);

export const ApiProvider = ({ children }) => {
    const apiHost = process.env.REACT_APP_API_HOST;
    const apiVersion = process.env.REACT_APP_API_VERSION;
    return (
        <ApiContext.Provider value={{ apiHost, apiVersion }}>{children}</ApiContext.Provider>
    );
};
