import React, { useState } from 'react'; 
import { XMarkIcon } from '@heroicons/react/24/solid';
import GmailIcon from '../assets/images/Gmail_icon.svg'; // Adjust the path as necessary
import { useApi } from "../contexts/ApiContext";


const PasswordRecovery = ({
  onClose,
}) => {
  const { apiHost, apiVersion } = useApi();
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setMessage('');
    setLoading(true);

    // Log the JSON structure being sent in the request
    console.log('Email being sent:', JSON.stringify({ email }));
    console.log('apiHost:', apiHost);
    console.log('apiVersion:', apiVersion);

    try {
      const response = await fetch(
        `${apiHost}${apiVersion}/forgot-password`,
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ email }), // Explicit assignment
          credentials: 'include',
        }
      );

      console.log('Response status:', response.status);
      console.log('Response headers:', response.headers);

      // Check if the response is JSON
      const contentType = response.headers.get('content-type');
      let data;

      if (contentType && contentType.includes('application/json')) {
        try {
          data = await response.json();
          console.log('Response data:', data);
        } catch (parseError) {
          console.error('Error parsing JSON:', parseError);
          const text = await response.text();
          console.log('Response text:', text);
          throw new Error('Failed to parse JSON response.');
        }
      } else {
        const text = await response.text();
        console.log('Response text:', text);
        throw new Error(`Unexpected response format: ${text}`);
      }

      if (response.ok) {
        setMessage(data.message || 'Recovery email sent successfully.');
      } else {
        setError(
          data.message ||
          data.errors?.email?.[0] ||
          'Failed to send recovery email.'
        );
      }
    } catch (err) {
      console.error('Error during password recovery:', err);
      setError(err.message || 'An error occurred. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div
      id="password-recovery-popup"
      tabIndex="-1"
      className="bg-black/50 fixed inset-0 flex items-center justify-center z-50"
    >
      <div className="relative p-4 w-full max-w-md bg-white rounded-lg shadow-md">
        <button
          type="button"
          className="absolute top-3 right-3 text-gray-400 hover:bg-gray-200 hover:text-gray-900 rounded-full p-1.5"
          onClick={onClose}
        >
          <XMarkIcon className="h-6 w-6 text-gray-500" />
          <span className="sr-only">Close popup</span>
        </button>

        <div className="p-6">
          <div className="text-center mb-4">
            <h2 className="text-2xl font-semibold text-slate-900">
              Recover Password
            </h2>
            <p className="text-sm text-slate-600 mt-2">
              Enter your email to receive a password recovery link.
            </p>
          </div>

          {error && (
            <p className="text-red-500 text-center mb-4">{error}</p>
          )}
          {message && (
            <div>
              <p className="text-green-500 text-center mb-4">{message}</p>

              {/* Gmail Button */}
              <div className="text-center">
                <a
                  href="https://mail.google.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-black hover:bg-red-600 focus:outline-none focus:ring-1 focus:ring-red-800"
                >
                  <img
                    src={GmailIcon}
                    alt="Gmail Icon"
                    className="w-5 h-5 mr-2" // Adjust the size accordingly
                  />
                  Open Gmail
                </a>
              </div>
            </div>
          )}

          <form className="space-y-4" onSubmit={handleSubmit}>
            <label htmlFor="email" className="sr-only">
              Email address
            </label>
            <input
              name="email"
              type="email"
              autoComplete="email"
              required
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="block w-full rounded-lg border border-gray-300 px-4 py-2 shadow-sm placeholder-gray-400 focus:ring-2 focus:ring-black"
              placeholder="Email Address"
            />

            <button
              type="submit"
              className={`w-full py-3 rounded-lg bg-black text-white font-medium text-sm focus:ring-2 focus:ring-black ${
                loading ? 'opacity-50 cursor-not-allowed' : ''
              }`}
              disabled={loading}
            >
              {loading ? 'Sending...' : 'Send Recovery Email'}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default PasswordRecovery;