import RecaptchaV3 from "./ReCaptcha";
import { useState } from "react";

export default function Test() {
  const handleVerifyCaptcha = (token) => {
    console.log(token);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form submitted");
  };

  return (
    <div className="container px-4 mx-auto">
      <div className="max-w-lg mx-auto">
        <div className="text-center mb-6">
          <h2 className="text-3xl md:text-4xl font-extrabold">Sign in</h2>
        </div>
        <form>
          <div className="mb-6">
            <label className="block mb-2 font-extrabold">Login</label>
            <input
              className="inline-block w-full p-4 leading-6 text-lg font-extrabold placeholder-indigo-900 bg-white shadow border-2 border-indigo-900 rounded"
              type="text"
              placeholder="email"
              required
            />
          </div>
          <div className="mb-6">
            <label className="block mb-2 font-extrabold">Password</label>
            <input
              className="inline-block w-full p-4 leading-6 text-lg font-extrabold placeholder-indigo-900 bg-white shadow border-2 border-indigo-900 rounded"
              type="password"
              placeholder="**********"
              required
            />
          </div>
          <button
            className="inline-block w-full py-4 px-6 mb-6 text-center text-lg leading-6 text-white font-extrabold bg-indigo-800 hover:bg-indigo-900 border-3 border-indigo-900 shadow rounded transition duration-200"
            type="submit"
            onClick={handleSubmit}
          >
            Sign in
          </button>
          <RecaptchaV3 action="submit" onVerify={handleVerifyCaptcha} />
        </form>
      </div>
    </div>
  );
}
