// App.js

import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import NavBar from "./components/NavBar";
import Footer from "./components/Footer";
import Events from "./components/Events";
import UsersList from "./components/Admin/UsersList";
import ResetPassword from "./components/ResetPassword";
import { GoogleOAuthProvider } from '@react-oauth/google';
import { AuthProvider } from './AuthContext';
import ErrorBoundary from './components/ErrorBoundary';
import Login from './components/Admin/Login';
import ProtectedRoute from './components/Admin/ProtectedRoute';
import LastBets from './components/Activity';
import CoinList from './components/CoinList';
import EventPanel from './components/EventPanel';
import Privacy from './components/Privacy';
import Tac from './components/Tac';
import About from './components/About';
import Settings from './components/User/Settings';
import Dashboard from './components/User/Dashboard';
import AssetRecords from './components/User/AssetRecords';
import OTPScreen from './components/User/OTPScreen';
import ConnectWallet from './components/User/ConnectWallet';
import LoginForm from './components/LoginForm';
import { CoinsProvider } from './contexts/CoinsContext';
import Activity from './components/RecentActivity';
import Test from './components/Test';
import PaymentStatus from "./components/PaymentStatus";
import { Toaster } from "react-hot-toast";
import AuthRoute from "./components/AuthRoute";
import MetaPixel from "./utils/meta/metaPixel";
import { ApiProvider } from "./contexts/ApiContext";
// Define apiHost and apiVersion (replace with your actual API host and version)
function App() {
    return (
        <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
            <MetaPixel />
            <Router>
                <ApiProvider>
                    <AuthProvider>
                        <CoinsProvider>
                                <ErrorBoundary>
                                    <NavBar />
                                    <Routes>
                                        <Route path="/" element={<MainPage />} />
                                        <Route path="/admin/login" element={<Login />} />
                                        <Route path="/admin/users" element={<ProtectedRoute><UsersList /></ProtectedRoute>} />
                                        {/* Routes for Password Recovery and Reset */}
                                        <Route
                                        path="/forgot-password"
                                        element={<ResetPassword  />}
                                        />
                                        {/* Main page */}
                                        <Route path="/event/:coin/:id" element={<EventPanel />} />
                                        <Route path="/privacy" element={<Privacy />} />
                                        <Route path="/activity" element={<Activity  />} />
                                        <Route path="/tac" element={<Tac />} />
                                        <Route path="/about" element={<About />} />

                                        {/* Auth Routes */}
                                        <Route element={<AuthRoute />}>
                                            <Route path="/dashboard" element={<Dashboard />} />
                                            <Route path="/user/settings" element={<Settings />} />
                                            <Route path="/user/assets/records" element={<AssetRecords />} />
                                            <Route path="/user/connect_wallet" element={<ConnectWallet />} />
                                            <Route path="/event/:coin/:id/history" element={<EventPanel isHistory={true} />} />
                                        </Route>
                                        {/* End of Auth Routes */}

                                        <Route path="/otp" element={<OTPScreen />} />
                                        <Route path="/test" element={<Test />} />
                                        <Route path="/payment/status/:gateway" element={<PaymentStatus />} />
                                        {/* LoginForm Route */}
                                        {/* <Route
                                        path="/login"
                                        element={<MainPage />}
                                        /> */}
                                    </Routes>
                                    <Footer />
                                    <Toaster
                                        position="top-right"
                                        reverseOrder={false}
                                        gutter={8}
                                        containerClassName=""
                                        containerStyle={{ top: 70 }}
                                        toastOptions={{
                                            // Define default options
                                            className: "",
                                            duration: 5000,
                                            style: {
                                                background: "#000",
                                                color: "#ffff",
                                                fontWeight: "500",
                                                boxShadow: "0 0 10px 0 rgba(0, 0, 0, 0.1)",
                                                minWidth: "150px",
                                            },
                        
                                            // Default options for specific types
                                            success: {
                                                duration: 3000,
                                                theme: {
                                                    primary: "green",
                                                    secondary: "black",
                                                },
                                            },
                                        }}
                                    />
                                </ErrorBoundary>
                        </CoinsProvider>
                    </AuthProvider>
                </ApiProvider>
            </Router>
        </GoogleOAuthProvider>
    );
}

const MainPage = () => (
  <>
    <div className="mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-2xl">
      <Events />
      <CoinList />
      <LastBets />
    </div>
  </>
);

export default App;