import React, { useEffect, useState, useRef, useCallback } from "react";
import { useParams } from "react-router-dom";
import { Line } from "react-chartjs-2";
import "chart.js/auto";
import "chartjs-adapter-date-fns";
import { useMediaQuery } from "react-responsive";
import { useApi } from "../contexts/ApiContext";
const timeFrames = ["1D", "1W", "1M", "3M", "1Y"];

const ChartComponent = ({ realTimePrice, start_time = null, end_time = null, isHistory = false }) => {
  const { coin } = useParams();
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
  const [chartData, setChartData] = useState(null);
  const [currentPrice, setCurrentPrice] = useState(realTimePrice);
  const [priceChange, setPriceChange] = useState(null);
  const [percentageChange, setPercentageChange] = useState(null);
  const [selectedTimeFrame, setSelectedTimeFrame] = useState("1D");
  const [chartOptions, setChartOptions] = useState({});
  const [isLoadingChartData, setIsLoadingChartData] = useState(false);
  const chartRef = useRef(null);
  const { apiHost, apiVersion } = useApi();
  // Interval reference for periodic updates
  // const intervalRef = useRef(null);

  // Helper functions defined before they are used
  const getTimeRange = useCallback((timeFrame) => {
    const now = Date.now();
    switch (timeFrame) {
      case "1D":
        return { min: now - 24 * 60 * 60 * 1000, max: now };
      case "1W":
        return { min: now - 7 * 24 * 60 * 60 * 1000, max: now };
      case "1M":
        return { min: now - 30 * 24 * 60 * 60 * 1000, max: now };
      case "3M":
        return { min: now - 90 * 24 * 60 * 60 * 1000, max: now };
      case "1Y":
        return { min: now - 365 * 24 * 60 * 60 * 1000, max: now };
      default:
        return { min: now - 24 * 60 * 60 * 1000, max: now };
    }
  }, []);

  //Granularity
  const getIntervalForTimeFrame = useCallback((timeFrame) => {
    switch (timeFrame) {
      case "1D":
        return "FIVE_MINUTE";
      case "1W":
        return "THIRTY_MINUTE";
      case "1M":
        return "ONE_HOUR";
      case "3M":
        return "SIX_HOUR";
      case "1Y":
        return "ONE_DAY";
      default:
        return "FIVE_MINUTE";
    }
  }, []);

  //Fetch data from time ago to now
  const getLimitForTimeFrame = useCallback((timeFrame) => {
    const nowInSeconds = Math.floor(Date.now() / 1000);
    switch (timeFrame) {
      case "1D":
        return nowInSeconds - 24 * 60 * 60; // 24 hours ago
      case "1W":
        return nowInSeconds - 7 * 24 * 60 * 60; // 7 days ago
      case "1M":
        return nowInSeconds - 30 * 24 * 60 * 60; // 30 days ago
      case "3M":
        return nowInSeconds - 90 * 24 * 60 * 60; // 90 days ago
      case "1Y":
        return nowInSeconds - 365 * 24 * 60 * 60; // 365 days ago
      default:
        return nowInSeconds - 24 * 60 * 60; // 1 day ago
    }
  }, []);

  const getTimeUnitForTimeFrame = useCallback((timeFrame) => {
    switch (timeFrame) {
      case "1D":
        return "hour";
      case "1W":
        return "day";
      case "1M":
      case "3M":
        return "week";
      case "1Y":
        return "month";
      default:
        return "hour";
    }
  }, []);

  const getChartOptions = useCallback(
    (timeRange) => {
      return {
        devicePixelRatio:
          typeof window !== "undefined" ? window.devicePixelRatio : 1,
        maintainAspectRatio: false,
        responsive: true,
        animation: false,
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            enabled: true,
            intersect: false,
            mode: "index",
            backgroundColor: "#fff",
            titleColor: "#000",
            bodyColor: "#000",
            padding: 10,
            cornerRadius: 4,
            displayColors: false,
            callbacks: {
              label: (context) => `$${context.parsed.y}`,
              title: () => null, // Do not show the date
            },
          },
        },
        elements: {
          point: {
            radius: 0,
          },
          line: {
            tension: isMobile ? 0 : 0.4,
          },
        },
        scales: {
          x: {
            type: "time",
            display: true, // Show gridlines and labels
            time: {
              unit: getTimeUnitForTimeFrame(selectedTimeFrame),
            },
            min: timeRange.min,
            max: timeRange.max,
            grid: {
              display: true,
              color: "#444",
            },
            ticks: {
              color: "#ccc",
            },
          },
          y: {
            display: true, // Show gridlines and labels
            position: "right", // Move the y-axis to the right
            grid: {
              display: true,
              color: "#444",
            },
            ticks: {
              color: "#ccc",
            },
          },
        },
      };
    },
    [isMobile, getTimeUnitForTimeFrame, selectedTimeFrame]
  );

  const getTimeStamp = (time) => {
    return Math.floor(new Date(time).getTime() / 1000);
  }

  const convertToLocalTime = (timestamp) => {
    const date = new Date(timestamp * 1000);
    if (isHistory) {
      return new Date(date.getTime() - date.getTimezoneOffset() * 60000);
    }
    return date;
  };

  const fetchChartData = useCallback(
    async (timeFrame) => {
      setIsLoadingChartData(true);
      try {
        const symbol = coin.toUpperCase().includes("-USD")
          ? coin.toUpperCase()
          : `${coin.toUpperCase()}-USD`;
        const granularity = getIntervalForTimeFrame(timeFrame);

        const start = start_time ? getTimeStamp(start_time) : getLimitForTimeFrame(timeFrame);
        const end = end_time ? getTimeStamp(end_time) : null;
        
        // Log the URL for debugging
        const url = `${apiHost}${apiVersion}/coinbase/product/candle?symbol=${symbol}&granularity=${granularity}&start=${start}${end ? `&end=${end}` : ''}`;
        
        const response = await fetch(url);
        const data = await response.json();

        if (data.code) {
          throw new Error(`Error fetching data: ${data.msg}`);
        }

        if (!Array.isArray(data) || data.length === 0) {
          throw new Error("Invalid or empty data received");
        }

        // Sort data by timestamp to ensure correct order
        const sortedData = [...data].sort((a, b) => a.start - b.start);

        // Convert timestamps to local time when creating labels
        const labels = sortedData.map(item => convertToLocalTime(item.start));
        const prices = sortedData.map(item => parseFloat(item.close));

        if (labels.length === 0 || prices.length === 0) {
          throw new Error("No data available for the selected time frame.");
        }

        const priceNow = prices[prices.length - 1];  // Use last price instead of first
        const initialPrice = prices[0];  // Use first price instead of last

        setCurrentPrice(priceNow);
        const priceChangeValue = priceNow - initialPrice;
        setPriceChange(priceChangeValue.toFixed(2));

        const percentage = ((priceNow - initialPrice) / initialPrice) * 100;
        setPercentageChange(percentage.toFixed(2));

        const isPriceUp = priceChangeValue >= 0;
        const lineColor = isPriceUp ? "#21ce99" : "#f45531";

        const processedData = {
          labels: labels,  // Remove reverse()
          datasets: [
            {
              data: prices,  // Remove reverse()
              borderColor: lineColor,
              backgroundColor: lineColor,
              fill: false,
              tension: isMobile ? 0 : 0.4,
              pointRadius: 0,
              borderWidth: 2,
            },
          ],
        };

        setChartData(processedData);
        setIsLoadingChartData(false);

        // Adjust time range based on actual data points
        const timeRange = {
          min: labels[0].getTime(),
          max: labels[labels.length - 1].getTime()
        };
        
        setChartOptions(getChartOptions(timeRange));
      } catch (error) {
        console.error("Error fetching chart data:", error);
        setIsLoadingChartData(false);
      }
    },
    [
      coin,
      selectedTimeFrame,
      isMobile,
      getIntervalForTimeFrame,
      getLimitForTimeFrame,
      getTimeRange,
      getChartOptions,
      apiHost,
      apiVersion,
      start_time,
      end_time
    ]
  );

  const updateChartData = useCallback(
    (newPrice) => {
      if (chartData && chartData.labels && chartData.datasets) {
        const newLabels = [...chartData.labels, new Date()];
        const newPrices = [...chartData.datasets[0].data, newPrice];

        const priceNow = newPrices[newPrices.length - 1];
        const initialPrice = newPrices[0];

        setCurrentPrice(priceNow);
        const priceChangeValue = priceNow - initialPrice;
        setPriceChange(priceChangeValue.toFixed(2));

        const percentage = ((priceNow - initialPrice) / initialPrice) * 100;
        setPercentageChange(percentage.toFixed(2));

        const isPriceUp = priceChangeValue >= 0;
        const lineColor = isPriceUp ? "#21ce99" : "#f45531";

        setChartData({
          labels: newLabels,
          datasets: [
            {
              ...chartData.datasets[0],
              data: newPrices,
              borderColor: lineColor,
              backgroundColor: lineColor,
            },
          ],
        });
      }
    },
    [chartData, selectedTimeFrame, getLimitForTimeFrame]
  );

  useEffect(() => {
    fetchChartData(selectedTimeFrame);
  }, [selectedTimeFrame, fetchChartData]);

  useEffect(() => {
    if (realTimePrice && !isHistory) {
      updateChartData(realTimePrice);
    }
  }, [realTimePrice]);

  const handleTimeFrameChange = (newTimeFrame) => {
    setSelectedTimeFrame(newTimeFrame);
  };

  return (
    <div className="chart-container w-full flex flex-col">
      {/* Chart Header */}
      <div className="text-center mb-4 text-white">
        <h1 className="text-2xl sm:text-3xl font-bold">
          {isHistory ? `The final price of ${coin.toUpperCase()} in this event was` : `What will be the price of ${coin.toUpperCase()}?`}
        </h1>
        {currentPrice && (
          <p className="text-xl sm:text-2xl">
            $
            {currentPrice.toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 8,
            })}
          </p>
        )}

        {/* Display price change and percentage change */}
        {priceChange && percentageChange && !isHistory && (
          <p
            className={`text-lg sm:text-xl ${
              priceChange >= 0 ? "text-green-500" : "text-red-500"
            }`}
          >
            {priceChange >= 0 ? `+${priceChange}` : `${priceChange}`} (
            {percentageChange}%)
          </p>
        )}
      </div>

      {/* Chart Container */}
      <div
        className="w-full flex-grow flex items-center justify-center"
        style={{ height: "400px" }}
      >
        {chartData && !isLoadingChartData ? (
          <Line ref={chartRef} data={chartData} options={chartOptions} />
        ) : (
          // Loading Spinner
          <div className="flex items-center justify-center">
            <div
              className="w-10 h-10 border-4 border-t-transparent border-white rounded-full animate-spin"
              role="status"
              aria-label="Loading chart"
            ></div>
          </div>
        )}
      </div>

      {/* Timeframes */}
      {!isHistory && (
        <div className="flex justify-center flex-wrap space-x-2 sm:space-x-4 mt-4">
            {timeFrames.map((timeFrame) => (
            <button
              key={timeFrame}
              onClick={() => handleTimeFrameChange(timeFrame)}
              className={`text-sm sm:text-base px-2 py-1 sm:px-3 sm:py-2 rounded-md ${
                selectedTimeFrame === timeFrame
                  ? "bg-gray-700 text-white"
                  : "text-gray-400"
              }`}
            >
            {timeFrame}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export default ChartComponent;
