import React, { useState, useEffect } from 'react';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { useParams } from 'react-router-dom';
import { useAuth } from "../AuthContext";
import { useApi } from "../contexts/ApiContext";

const OrderBook = ({ id, isHistory = false, myBets = false }) => {
  const [isOpen, setIsOpen] = useState(true);
  const [orders, setOrders] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const { user, isAuthenticated } = useAuth();
  const ordersPerPage = 10;
  const { apiHost, apiVersion } = useApi();
  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const fetchOrders = async () => {
      const user_id = (isAuthenticated && myBets) ? user.id : null;

      let endpoint = (isHistory || myBets) ? "/market/order-history" : "/market/live-order";
      // Placeholder for real-time data fetching logic
      const response = await fetch(`${apiHost}${apiVersion}${endpoint}`,{
        method: 'POST',
        headers: {
          ...((isHistory || myBets) && {
            Authorization: `Bearer ${localStorage.getItem('sanctum_token')}`
          }),
          'Content-Type': 'application/json',
        },
        body: user_id ? JSON.stringify({ id, user_id }) : JSON.stringify({ id }),
        credentials: 'include',
      });
      const data = await response.json();
      setOrders(data);
    };
    if(id) {
      fetchOrders();
      if (!isHistory) {
        const interval = setInterval(fetchOrders, 5000);
        return () => clearInterval(interval);
      }
    } else {
      setOrders([]);
    }
  }, [id]);

  // Ensure orders is always an array
  const safeOrders = Array.isArray(orders) ? orders : [];

  const indexOfLastOrder = currentPage * ordersPerPage;
  const indexOfFirstOrder = indexOfLastOrder - ordersPerPage;
  const currentOrders = safeOrders.slice(indexOfFirstOrder, indexOfLastOrder);
  const totalPages = Math.ceil(safeOrders.length / ordersPerPage);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <div className="text-white border border-gray-300 rounded-md shadow-lg">
      <div
        className="flex items-center justify-between cursor-pointer px-4 py-4"
        onClick={toggleOpen}
      >
        <h2 className="text-lg font-semibold">{(isHistory || myBets) ? "Your Bets" : "Order Book"}</h2>
        {isOpen ? (
          <FaChevronUp className="text-lg" />
        ) : (
          <FaChevronDown className="text-lg" />
        )}
      </div>

      <div
        className={`overflow-hidden transition-all duration-500 ease-in-out ${
          isOpen ? 'max-h-screen' : 'max-h-0'
        }`}
      >
        {isOpen && (
          <div className="overflow-x-auto">
            <table className="min-w-full table-auto">
              <thead>
                <tr className="text-sm text-gray-500">
                  <th className="px-4 py-2 text-left">Username</th>
                  {(isHistory || myBets) && (
                    <th className="px-4 py-2 text-left">Prediction</th>
                  )}
                  <th className="px-4 py-2 text-center w-3/12">Amount</th>
                  <th className="px-4 py-2 text-center w-3/12">Ordered At</th>
                </tr>
              </thead>
              <tbody>
                {currentOrders.length > 0 ? (
                  currentOrders.map((order, index) => (
                    <tr key={index} className="">
                      <td className="px-4 py-2">{order.username}</td>
                      {(isHistory || myBets) && (
                        <td className="px-4 py-2">{order.prediction}</td>
                      )}
                      <td className="px-4 py-2 text-center">
                        ${order.amount}
                      </td>
                      <td className="px-4 py-2 text-center">
                        {new Date(order.order_at).toLocaleString()}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td
                      colSpan={ (isHistory || myBets) ? "4" : "3" }
                      className="px-4 py-2 text-center"
                    >
                      No orders found
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        )}
      </div>
      {isOpen && (
        <div className="flex justify-center mt-4 px-4 pb-4">
          <button
            onClick={() => paginate(currentPage - 1)}
            hidden={currentPage === 1}
            className={`px-4 py-2 rounded-md transition duration-300 ease-in-out ${
              currentPage === 1
                ? 'bg-neutral-800 text-gray-300 cursor-not-allowed'
                : 'bg-neutral-800 text-gray-300 hover:bg-gray-300 hover:text-black'
            }`}
          >
            &laquo;
          </button>

          {Array.from({ length: totalPages }, (_, index) => (
            <button
              key={index}
              onClick={() => paginate(index + 1)}
              className={`px-4 py-2 mx-1 rounded-md transition duration-300 ease-in-out ${
                currentPage === index + 1
                  ? 'bg-neutral-800 text-white'
                  : 'bg-neutral-800 text-gray-300 hover:bg-gray-300 hover:text-black'
              }`}
            >
              {index + 1}
            </button>
          ))}

          <button
            onClick={() => paginate(currentPage + 1)}
            hidden={currentPage === totalPages}
            className={`px-4 py-2 rounded-md transition duration-300 ease-in-out ${
              currentPage === totalPages
                ? 'bg-neutral-800 text-gray-300 cursor-not-allowed'
                : 'bg-neutral-800 text-gray-300 hover:bg-gray-300 hover:text-black'
            }`}
          >
            &raquo;
          </button>
        </div>
      )}
    </div>
  );
};

export default OrderBook;
