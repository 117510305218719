import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "../AuthContext";

const AuthRoute = () => {
  const { toggleModal } = useAuth();

  const user = localStorage.getItem('user');
  const token = localStorage.getItem('sanctum_token');

  if(!user && !token){
    toggleModal();
    return <Navigate to="/" replace/>
  }

  return  <Outlet />;
};

export default AuthRoute;
