import React from 'react';

const ConnectWallet = () => {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
      <div className="bg-white p-6 rounded-lg shadow-lg max-w-md w-full">
        <h2 className="text-2xl font-semibold mb-4 text-center">Connect your wallet</h2>
        <p className="text-center text-gray-600 mb-6">
          Lorem ipsum dolor sit amet, consectetur.
        </p>
        <button className="absolute top-2 right-2 text-gray-500 hover:text-gray-700">
          Close
        </button>
        <h3 className="text-lg font-semibold mb-4 text-center">Choose Wallet</h3>
        <p className="text-center text-gray-600 mb-6">
          Lorem ipsum dolor sit amet, consectetur.
        </p>
        <div className="space-y-4">
          <button className="w-full bg-blue-500 text-white p-3 rounded-lg hover:bg-blue-600">
            Metamask
          </button>
          <button className="w-full bg-blue-500 text-white p-3 rounded-lg hover:bg-blue-600">
            Coinbase Wallet
          </button>
          <button className="w-full bg-blue-500 text-white p-3 rounded-lg hover:bg-blue-600">
            Wallet Connect
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConnectWallet;
