// Spinner.js
import React from 'react';

const Spinner = () => (
  <svg
    className="animate-spin h-8 w-8 text-gradient"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 100"
    role="status"
    aria-label="Loading"
  >
    <defs>
      <linearGradient id="spinnerGradient" x1="0%" y1="0%" x2="100%" y2="0%">
        <stop offset="0%" stopColor="#4f46e5" /> {/* Indigo-600 */}
        <stop offset="100%" stopColor="#ec4899" /> {/* Pink-500 */}
      </linearGradient>
    </defs>
    {/* Outer Ring */}
    <circle
      cx="50"
      cy="50"
      r="45"
      stroke="url(#spinnerGradient)"
      strokeWidth="10"
      fill="none"
      strokeLinecap="round"
      className="opacity-75"
    />
    {/* Inner Ring */}
    <circle
      cx="50"
      cy="50"
      r="35"
      stroke="url(#spinnerGradient)"
      strokeWidth="10"
      fill="none"
      strokeLinecap="round"
      className="opacity-75 animate-spin-reverse"
    />
  </svg>
);

export default Spinner;
