// AssetRecords.js
import React, { useState, useEffect } from 'react';
import { FaArrowDown, FaArrowUp, FaDollarSign } from 'react-icons/fa';
import Pagination from "../Pagination";
import { useApi } from "../../contexts/ApiContext";

const AssetRecords = () => {
  const { apiHost, apiVersion } = useApi();
  const [records, setRecords] = useState([]);

  const [recordsLoading, setRecordsLoading] = useState(true);
  const [currentRecordPage, setCurrentRecordPage] = useState(1);
  const [totalRecordPage, setTotalRecordPage] = useState(0);
  const [paginationLoading, setPaginationLoading] = useState(true);

  useEffect(() => {
    const fetchRecords = async () => {
      try {
        const response = await fetch(
          `${apiHost}${apiVersion}/asset-records?page=${currentRecordPage}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${localStorage.getItem("sanctum_token")}`,
              "Content-Type": "application/json",
            },
            credentials: "include",
          }
        );

        if (! response.ok ) {
          throw new Error(`Error fetching asset records: ${response.statusText}`);
        }

        const data = await response.json();
        console.log(data);
        let assetRecordResonse = [];
        data.data.data.forEach(record => {
          assetRecordResonse.push({
            id: record.id,
            date: new Date(record.created_at).toLocaleDateString(),
            type: formatResponseType(record.type),
            amount: formatAmount(record, record.before_bal, record.after_bal),
            currency: record.transaction_gateway ? record.transaction_gateway.currency : 'USD',
          })
        });
        
        setRecords(assetRecordResonse);
        setCurrentRecordPage(data.data.current_page)
        setTotalRecordPage(data.data.last_page)
      } catch (error) {
        console.error("Error fetching asset records:", error);
        setRecords([]);
      } finally {
        setRecordsLoading(false);
        setPaginationLoading(false);
      }
    };

    fetchRecords();
  }, [currentRecordPage]);

  const formatResponseType = (type) => {
    let recordType = ''
    switch (type) {
      case 'deposit':
        recordType = 'Deposit'
        break;

      case 'withdraw':
        recordType = 'Withdrawal'
        break;

      case 'payout':
        recordType = 'Payout'
        break;
    
      default:
        break;
    }

    return recordType;
  }

  const formatAmount = (record, beforeBal, afterBal) => {
    let amount = record.amount;
    if( record.type == 'deposit' && record.transaction_gateway ) {
      const validGateway = ['finsol', 'expay'];
      if( validGateway.includes(record.transaction_gateway.gateway) ) {
        amount = (afterBal - beforeBal).toFixed(2);
      }
    }

    if( record.type == 'withdraw' ) {
      amount = -amount;
    }

    return amount;
  }

  const handlePageChange = (page) => {
    setCurrentRecordPage(page);
    setPaginationLoading(true);
  };

  const getTypeStyles = (type) => {
    switch (type) {
      case 'Deposit':
        return 'bg-green-100 text-green-800';
      case 'Withdrawal':
        return 'bg-red-100 text-red-800';
      case 'Payout':
        return 'bg-blue-100 text-blue-800';
      default:
        return 'bg-gray-100 text-gray-800';
    }
  };

  const getTypeIcon = (type) => {
    switch (type) {
      case 'Deposit':
        return <FaArrowDown className="text-green-500" />;
      case 'Withdrawal':
        return <FaArrowUp className="text-red-500" />;
      case 'Payout':
        return <FaDollarSign className="text-blue-500" />;
      default:
        return null;
    }
  };

  return (
    <div className="container mx-auto p-4">
      <h2 className="text-2xl md:text-3xl font-semibold mb-4 text-center text-white">
        Asset Records
      </h2>

      {/* Table for medium and larger screens */}
      <div className="hidden md:block">
        <div className="overflow-x-auto">
          <table className="min-w-full bg-gray-800 shadow rounded-lg overflow-hidden">
            <thead className="bg-gray-700">
              <tr>
                <th className="py-3 px-6 text-left text-gray-300 font-medium uppercase">
                  Date
                </th>
                <th className="py-3 px-6 text-left text-gray-300 font-medium uppercase">
                  Type
                </th>
                <th className="py-3 px-6 text-right text-gray-300 font-medium uppercase">
                  Amount
                </th>
              </tr>
            </thead>
            <tbody>
              { recordsLoading 
                ? <tr className='border-b border-gray-700 text-gray-300'>
                    <td colSpan="3" className='text-center py-4 px-6'>Loading...</td>
                  </tr>
                : <></> 
              }
              {records.map((record) => (
                <tr
                  key={record.id}
                  className="border-b border-gray-700 hover:bg-gray-700 text-gray-300"
                >
                  <td className="py-4 px-6">{record.date}</td>
                  <td className="py-4 px-6 flex items-center">
                    <span className="mr-2">{getTypeIcon(record.type)}</span>
                    <span
                      className={`px-2 py-1 rounded-full text-sm font-semibold ${getTypeStyles(
                        record.type
                      )}`}
                    >
                      {record.type}
                    </span>
                  </td>
                  <td className="py-4 px-6 text-right">
                    {record.amount > 0 ? '+' : ''}
                    {record.amount} {record.currency}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          { totalRecordPage > 1 && (
            <Pagination
              totalPages={totalRecordPage}
              currentPage={currentRecordPage}
              onPageChange={handlePageChange}
              isLoading={paginationLoading}
            />
          )}
        </div>
      </div>

      {/* Cards for small screens */}
      <div className="md:hidden">
        {records.map((record) => (
          <div
            key={record.id}
            className="bg-gray-800 shadow rounded-lg mb-4 p-4 text-gray-300"
          >
            <div className="flex items-center justify-between mb-2">
              <div className="flex items-center">
                <span className="mr-2">{getTypeIcon(record.type)}</span>
                <span
                  className={`px-2 py-1 rounded-full text-sm font-semibold ${getTypeStyles(
                    record.type
                  )}`}
                >
                  {record.type}
                </span>
              </div>
              <div className="text-sm text-gray-400">{record.date}</div>
            </div>
            <div className="text-right text-lg font-semibold">
              {record.amount > 0 ? '+' : ''}
              {record.amount} {record.currency}
            </div>
          </div>
        ))}
        { totalRecordPage > 1 && (
            <Pagination
              totalPages={totalRecordPage}
              currentPage={currentRecordPage}
              onPageChange={handlePageChange}
              isLoading={paginationLoading}
            />
          )}
      </div>
    </div>
  );
};

export default AssetRecords;
