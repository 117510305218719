// src/contexts/CoinsContext.js

import React, {
  createContext,
  useState,
  useEffect,
  useRef,
  useCallback,
} from "react";
import { useApi } from "./ApiContext";
export const CoinsContext = createContext();

export const CoinsProvider = ({ children }) => {
  const [coinsData, setCoinsData] = useState([]);
  const [isDataFetched, setIsDataFetched] = useState(false);
  const [subscribedSymbols, setSubscribedSymbols] = useState([]);
  const [symbols, setSymbols] = useState([]);
  const [error, setError] = useState(null);
  const ws = useRef(null);
  const { apiHost, apiVersion } = useApi();
  const [wsErrorCount, setWsErrorCount] = useState(0);
  const MAX_WS_ERRORS = 3;
  const reconnectTimeoutRef = useRef(null);
  const isConnecting = useRef(false);

  const fetchBatchHistoricalData = useCallback(async (symbol) => {
    try {
      const url = `${apiHost}${apiVersion}/coinbase/candles?symbol=${symbol.toUpperCase()}`;
      const response = await fetch(url);
      const data = await response.json();
      return data;
    } catch (error) {
      console.error(`Error fetching historical data for ${symbol}:`, error);
      setError(`Error fetching historical data for ${symbol}`);
      return [];
    }
  }, [apiHost, apiVersion]);

  const fetchCoinsData = useCallback(async () => {
    try {
      const response = await fetch(
        `${apiHost}${apiVersion}/list`
      );
      const data = await response.json();

      const symbolsMapping = data.reduce((acc, symbol) => {
        acc[symbol.symbol] = symbol.id;
        return acc;
      }, {});

      setSymbols(Object.keys(symbolsMapping));

      const liveCoins = await Promise.all(
        data.map(async (coin) => {
          const historicalData = await fetchBatchHistoricalData(coin.symbol);
          const priceTrend = historicalData.map((item) =>
            parseFloat(item.close)
          ); // Coinbase's close price is index 4
          const currentPrice = priceTrend[priceTrend.length - 1] || null;
          const initialPrice = priceTrend[0] || null;

          let percentageChange = null;
          if (initialPrice && currentPrice) {
            percentageChange =
              ((currentPrice - initialPrice) / initialPrice) * 100;
          }

          return {
            ...coin,
            priceTrend,
            currentPrice,
            percentageChange,
            initialPrice,
          };
        })
      );
      setCoinsData(liveCoins);
      setIsDataFetched(true);
    } catch (error) {
      console.error("Error fetching coin data:", error);
      setError("Error fetching coin data");
    }
  }, [apiHost, apiVersion, fetchBatchHistoricalData]);

  useEffect(() => {
    fetchCoinsData();
  }, [fetchCoinsData]);

  const unsubscribeFromChannels = useCallback(() => {
    if (
      ws.current &&
      ws.current.readyState === WebSocket.OPEN &&
      subscribedSymbols.length > 0
    ) {
      const unsubscribeMessage = {
        type: "unsubscribe",
        product_ids: subscribedSymbols.map((symbol) => symbol.toUpperCase()),
      };

      ws.current.send(JSON.stringify(unsubscribeMessage));
      setSubscribedSymbols([]);
    }
  }, [subscribedSymbols]);

  const connectWebSocket = useCallback(() => {
    if (isConnecting.current) {
      //console.log("Connection attempt already in progress...");
      return;
    }

    if (ws.current?.readyState === WebSocket.OPEN) {
      //console.log("WebSocket is already connected");
      return;
    }

    isConnecting.current = true;

    if (ws.current) {
      ws.current.close();
    }

    ws.current = new WebSocket("wss://ws-feed.exchange.coinbase.com");

    ws.current.onopen = () => {
      //console.log("WebSocket connection opened");
      setWsErrorCount(0);
      setError("");
      isConnecting.current = false;
      
      const productIds = symbols.map((symbol) => symbol.toUpperCase());
      const subscribeMessage = {
        type: "subscribe",
        product_ids: productIds,
        channels: [{ name: "ticker_batch", product_ids: productIds }],
      };
      ws.current.send(JSON.stringify(subscribeMessage));
    };

    ws.current.onmessage = (event) => {
      const message = JSON.parse(event.data);
      if (message.type === "subscriptions") {
        
      }

      if (message.type === "ticker" && message.product_id && message.price) {
        const symbol = message.product_id.replace("-USD", "");
        const price = parseFloat(message.price);
        setCoinsData((prevCoins) =>
          prevCoins.map((coin) => {
            if (coin.name === symbol) {
              return { ...coin, currentPrice: price };
            }
            return coin;
          })
        );
      }
    };

    ws.current.onerror = (error) => {
      console.error("WebSocket error:", error);
      isConnecting.current = false;
    };

    ws.current.onclose = (event) => {
      //console.log(`WebSocket closed with code ${event.code}, reason: ${event.reason}`);
      isConnecting.current = false;

      setWsErrorCount(prevCount => {
        const newCount = prevCount + 1;
        
        // Handle specific close codes
        switch (event.code) {
          case 1000: // Normal closure
            //console.log("Clean websocket closure - no reconnect needed");
            return prevCount;
          case 1005: // No status received
            //console.log("No status received from server");
            break;
          case 1006: // Abnormal closure
            //console.log("Abnormal closure - will attempt reconnect");
            break;
          default:
            //console.log(`Unknown close code: ${event.code}`);
        }
        
        if (newCount >= MAX_WS_ERRORS) {
          //console.log(`WebSocket encountered ${MAX_WS_ERRORS} errors. Reloading page in 5 seconds...`);
          setError("Connection issues detected. Page will reload in 5 seconds...");
          setTimeout(() => {
            window.location.reload();
          }, 5000);
          return newCount;
        }

        if (!isConnecting.current) {
          const reconnectDelay = Math.min(1000 * Math.pow(2, newCount), 10000);
          //console.log(`Attempting to reconnect in ${reconnectDelay}ms...`);
          
          if (reconnectTimeoutRef.current) {
            clearTimeout(reconnectTimeoutRef.current);
          }
          
          reconnectTimeoutRef.current = setTimeout(() => {
            connectWebSocket();
          }, reconnectDelay);
        }
        
        return newCount;
      });
    };
  }, [symbols]);

  useEffect(() => {
    if (isDataFetched) {
      connectWebSocket();

      return () => {
        isConnecting.current = false;
        if (reconnectTimeoutRef.current) {
          clearTimeout(reconnectTimeoutRef.current);
        }
        unsubscribeFromChannels();
        if (ws.current) {
          ws.current.close();
        }
        setWsErrorCount(0);
      };
    }
  }, [isDataFetched, connectWebSocket, unsubscribeFromChannels]);

  const getCoinData = (id) => {
    const coin = coinsData.find((coin) => coin.id === parseInt(id));
    if (!coin) {
      console.log("Coin not found");
    }
    return coin;
  };

  return (
    <CoinsContext.Provider
      value={{
        coinsData,
        isDataFetched,
        fetchBatchHistoricalData,
        error,
        getCoinData,
        fetchCoinsData,
      }}
    >
      {children}
    </CoinsContext.Provider>
  );
};
