import React, { useState, useEffect, useContext } from "react";
import { CoinsContext } from "../contexts/CoinsContext";

const Countdown = ({ targetDate, className = "", children }) => {
  const [timeLeft, setTimeLeft] = useState("");
  const [isLastTwoHours, setIsLastTwoHours] = useState(false);
  const { fetchCoinsData } = useContext(CoinsContext);

  useEffect(() => {
    if (!targetDate) {
      console.error("Invalid targetDate");
      setTimeLeft("Invalid date");
      return;
    }

    // Ensure we're working with UTC by appending Z if it's not already there
    const utcDate = targetDate.endsWith('Z') ? targetDate : targetDate + 'Z';
    const countDownDate = new Date(utcDate).getTime();

    if (isNaN(countDownDate)) {
      console.error("Invalid targetDate format");
      setTimeLeft("Invalid date");
      return;
    }

    const interval = setInterval(() => {
      const now = new Date().getTime();
      const distance = countDownDate - now;

      if (distance < 0) {
        clearInterval(interval);
        setTimeLeft("EXPIRED");
        setInterval(() => {
          fetchCoinsData();
        }, 30000);
      } else {
        const days = Math.floor(distance / (1000 * 60 * 60 * 24));
        const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((distance % (1000 * 60)) / 1000);

        setTimeLeft(
          `${days > 0 ? `${days}d ` : ""}${hours > 0 ? `${hours}h ` : ""}${
            minutes > 0 ? `${minutes}m ` : ""
          }${seconds}s`
        );

        // Check if we're within the last two hours
        setIsLastTwoHours(distance <= 2 * 60 * 60 * 1000);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [targetDate]);

  return (
    <p className="flex items-center">
    <span
      id="countdown"
      className={`text-sm font-semibold mr-1 ${
        isLastTwoHours ? "!text-red-500 blinking" : ""
      } ${timeLeft ? className : ""}`}
    >
      {timeLeft} 
    </span>
    { timeLeft && children}
    </p>
  );
};

export default Countdown;
