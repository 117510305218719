import React, { useState, useEffect, useMemo } from "react";
import { useAuth } from "../../AuthContext";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import { FaCog, FaWallet, FaInfoCircle, FaSpinner } from "react-icons/fa";
import BetHistory from "./BetHistory";
import DepositModal from "../DepositModal";
import Pagination from "../Pagination";
import WithdrawalModal from "../WithdrawalModal";
import AssetRecords from "./AssetRecords"; // Import AssetRecords component
import { Tooltip } from "flowbite-react";
import { useApi } from "../../contexts/ApiContext";

function Dashboard() {
  const {
    user,
    balance,
    fetchBalance,
    hasBonus,
    totalBonus,
    balanceError,
    isBalanceLoading,
    forceBalanceRefresh,
  } = useAuth();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const urlPaymentStatus = searchParams.get("status");

  // Bet history state variables
  const [betHistory, setBetHistory] = useState([]);
  const [historyLoading, setHistoryLoading] = useState(true);
  const [currentHistoryPage, setCurrentHistoryPage] = useState(1);
  const [totalHistoryPage, setTotalHistoryPage] = useState(0);
  const [coinList, setCoinList] = useState([]);
  const [finsolBankList, setFinsolBankList] = useState([]);

  const location = useLocation();
  const { shouldOpenDepositModal } = location.state || {};
  const { apiHost, apiVersion } = useApi();
  useEffect(() => {
    fetchBalance();
  }, []);

  useEffect(() => {
    const fetchBetHistory = async () => {
      setHistoryLoading(true);

      try {
        const response = await fetch(
          `${apiHost}${apiVersion}/transaction-history`,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${localStorage.getItem("sanctum_token")}`,
              "Content-Type": "application/json",
            },
            credentials: "include",
            body: JSON.stringify({
              type: "order",
              page: currentHistoryPage,
            }),
          }
        );

        if (!response.ok) {
          throw new Error(
            `Error fetching bet history: ${response.statusText}`
          );
        }

        const data = await response.json();
        setBetHistory(data.order.data);
        setTotalHistoryPage(data.order.last_page);
      } catch (error) {
        console.error("Error fetching bet history:", error);
        setBetHistory([]);
      } finally {
        setHistoryLoading(false);
      }
    };

    fetchBetHistory();
  }, [currentHistoryPage]);

  useEffect(() => {
    const fetchList = async () => {
      try {
        const response = await fetch(
          `${apiHost}${apiVersion}/currency-list`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${localStorage.getItem("sanctum_token")}`,
              "Content-Type": "application/json",
            },
            credentials: "include",
          }
        );

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data = await response.json();

        setCoinList(data.data);
      } catch (error) {
        console.error("Error fetching currency list:", error);
      }
    };

    fetchList();
  }, []);

  useEffect(() => {
    const fetchBankList = async () => {
      try {
        const response = await fetch(
          `${apiHost}${apiVersion}/finsol/bank-list`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${localStorage.getItem("sanctum_token")}`,
              "Content-Type": "application/json",
            },
            credentials: "include",
          }
        );

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data = await response.json();
        setFinsolBankList(data.currency_bank_list);
      } catch (error) {
        console.error("Error fetching bank list:", error);
      }
    };

    fetchBankList();
  }, []);

  const handleSettingsClick = () => {
    navigate("/user/settings");
  };

  const handlePageChange = (page) => {
    setCurrentHistoryPage(page);
  };

  // Deposit state variables
  const [showDepositModal, setShowDepositModal] = useState(false);
  const [isPaymentProcessed, setIsPaymentProcessed] = useState(false);
  const [paymentStatus, setPaymentStatus] = useState(false);

  const toggleDepositModal = () => {
    setShowDepositModal(!showDepositModal);
  };

  const handleDepositModal = () => {
    setIsPaymentProcessed(false);
    toggleDepositModal();
  };

  useEffect(() => {
    if (shouldOpenDepositModal) {
      setTimeout(() => {
        handleDepositModal();
      }, 500);
    }
  }, [shouldOpenDepositModal]);

  useEffect(() => {
    if (urlPaymentStatus) {
      setIsPaymentProcessed(true);
      setPaymentStatus(urlPaymentStatus);

      toggleDepositModal();

      searchParams.delete("status");
      searchParams.delete("gateway");
      setSearchParams(searchParams, { replace: true });
    }
  }, [urlPaymentStatus, setSearchParams, searchParams]);

  // Withdrawal state variables
  const [showWithdrawalModal, setShowWithdrawalModal] = useState(false);
  const [isWithdrawalProcessed, setIsWithdrawalProcessed] = useState(false);

  const toggleWithdrawalModal = () => {
    setShowWithdrawalModal(!showWithdrawalModal);
  };

  const handleWithdrawalModal = async () => {
    setIsWithdrawalProcessed(false);
    await forceBalanceRefresh();
    toggleWithdrawalModal();
  };

  // Tab state
  const [activeTab, setActiveTab] = useState("assetRecords");

  const renderTabContent = () => {
    switch (activeTab) {
      case "assetRecords":
        return <AssetRecords />;
      case "betHistory":
        return (
          <>
            <BetHistory bets={betHistory} historyLoading={historyLoading} />
            {!historyLoading && totalHistoryPage > 1 && (
              <Pagination
                totalPages={totalHistoryPage}
                currentPage={currentHistoryPage}
                onPageChange={handlePageChange}
                isLoading={historyLoading}
              />
            )}
          </>
        );
      default:
        return null;
    }
  };

  const formattedBalance = useMemo(() => {
    return balance !== null ? balance.toFixed(2) : '0.00';
  }, [balance]);

  return (
    <div className="mt-20 p-4 text-white">
      {/* Header */}
      <div className="flex flex-col sm:flex-row justify-between items-center p-6 rounded-lg shadow-lg bg-gradient-to-r from-purple-500 to-indigo-600">
        <div>
          <h2 className="text-3xl font-bold mb-2">
            Welcome back, {user ? user.name : (localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")).name : "Guest")}
          </h2>
          <p className="text-sm text-gray-200">
            Here's what's happening with your account today.
          </p>
        </div>
        <button
          className="text-white hover:text-gray-300 focus:outline-none mt-4 sm:mt-0"
          onClick={handleSettingsClick}
        >
          <FaCog size={24} />
        </button>
      </div>

      {/* Main Content */}
      <div className="mt-8 grid grid-cols-1 lg:grid-cols-3 gap-6">
        {/* Balance Card */}
        <div className="col-span-1 bg-gray-800 p-6 rounded-lg shadow-lg">
          <div className="flex items-center">
            <div className="p-4 bg-indigo-500 rounded-full">
              <FaWallet size={24} />
            </div>
            <div className="ml-4">
              <h3 className="text-lg font-semibold">Total Balance</h3>
              {balanceError ? (
                <div className="text-red-500 text-sm">
                  Error loading balance. Please try again.
                </div>
              ) : isBalanceLoading ? (
                <div className="flex items-center text-2xl">
                  <FaSpinner className="animate-spin mr-2" />
                  Loading...
                </div>
              ) : (
                <div className="flex items-center text-2xl font-bold">
                  ${formattedBalance} &nbsp;
                  {hasBonus && (
                    <div className="flex items-center">
                      + &nbsp;${totalBonus} Bonus
                      <Tooltip
                        content={
                          <div className="w-80">
                            <p className="font-bold">Bonus Rewards</p>
                            <p>
                              Bonus can't be used to bet and can only be
                              withdrawn when you won a position (1st, 2nd, 3rd)
                              in your bet prediction.
                            </p>
                          </div>
                        }
                        className="shadow-lg p-4 rounded-lg"
                      >
                        <FaInfoCircle className="m-2" size={14} />
                      </Tooltip>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
          <div className="mt-6 flex space-x-2">
            <button
              className="flex-1 px-4 py-2 font-bold bg-green-500 hover:bg-green-600 text-white rounded"
              onClick={handleDepositModal}
            >
              Deposit
            </button>
            <button
              className="flex-1 px-4 py-2 font-bold bg-red-500 hover:bg-red-600 text-white rounded"
              onClick={handleWithdrawalModal}
            >
              Withdraw
            </button>
          </div>
        </div>

        {/* Additional Cards */}
        <div className="col-span-1 lg:col-span-2 grid grid-cols-1 md:grid-cols-2 gap-6">
          {/* Recent Activity */}
          <div className="bg-gray-800 p-6 rounded-lg shadow-lg">
            <h3 className="text-lg font-semibold mb-4">Recent Activity</h3>
            {/* Placeholder for recent activity */}
            <p className="text-gray-400">No recent activity.</p>
          </div>

          {/* Statistics */}
          <div className="bg-gray-800 p-6 rounded-lg shadow-lg">
            <h3 className="text-lg font-semibold mb-4">Statistics</h3>
            {/* Placeholder for statistics */}
            <p className="text-gray-400">No statistics available.</p>
          </div>
        </div>
      </div>

      {/* Simple Tabs for Asset Records and Bet History */}
      <div className="mt-8 bg-gray-800 p-6 rounded-lg shadow-lg">
        <div className="flex border-b border-gray-700">
          <button
            onClick={() => setActiveTab("assetRecords")}
            className={`flex-1 text-center py-2 ${
              activeTab === "assetRecords"
                ? "border-b-2 border-blue-500 text-blue-500"
                : "text-gray-400 hover:text-gray-200"
            }`}
          >
            Asset Records
          </button>
          <button
            onClick={() => setActiveTab("betHistory")}
            className={`flex-1 text-center py-2 ${
              activeTab === "betHistory"
                ? "border-b-2 border-blue-500 text-blue-500"
                : "text-gray-400 hover:text-gray-200"
            }`}
          >
            Bet History
          </button>
        </div>
        <div className="mt-4">{renderTabContent()}</div>
      </div>

      {/* Deposit Modal */}
      {showDepositModal && (
        <DepositModal
          onClose={toggleDepositModal}
          isPaymentProcessed={isPaymentProcessed}
          paymentStatus={paymentStatus}
          currnecyList={coinList}
        />
      )}

      {/* Withdrawal Modal */}
      {showWithdrawalModal && (
        <WithdrawalModal
          onClose={toggleWithdrawalModal}
          isWithdrawalProcessed={isWithdrawalProcessed}
          currnecyList={coinList}
          fetchBalance={forceBalanceRefresh}
          finsolBankList={finsolBankList}
        />
      )}
    </div>
  );
}

export default Dashboard;
