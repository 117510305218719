import React, { useEffect } from 'react';
import '../TelegramLogin.css';

const TelegramLogin = ({ onAuth }) => {
  const botName = process.env.REACT_APP_TELEGRAM_BOT_NAME;
  
  useEffect(() => {
    // First, set up the global callback
    window.onTelegramAuth = function(user) {
      console.log('Telegram auth successful:', user);
      onAuth(user);
    };

    const script = document.createElement('script');
    script.src = 'https://telegram.org/js/telegram-widget.js?22';
    script.setAttribute('data-telegram-login', botName);
    script.setAttribute('data-size', 'large');
    script.setAttribute('data-radius', '8');
    script.setAttribute('data-request-access', 'write');
    script.setAttribute('data-userpic', 'false');
    script.setAttribute('data-expand', 'true');
    script.setAttribute('data-full-width', 'true');
    // Important: Make sure this matches exactly with the global function name
    script.setAttribute('data-onauth', 'window.onTelegramAuth(user)');
    script.async = true;

    const container = document.getElementById('telegram-login-container');
    if (container) {
      container.appendChild(script);
    }

    return () => {
      if (container && container.contains(script)) {
        container.removeChild(script);
      }
      // Cleanup the global callback
      delete window.onTelegramAuth;
    };
  }, [onAuth, botName]);

  return (
    <div 
      id="telegram-login-container" 
      className="w-full" 
      style={{
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        marginTop: '1rem',
      }}
    />
  );
};

export default TelegramLogin; 