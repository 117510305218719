import React, { useState, useEffect, useContext } from "react";
import BetPanel from "./BetPanel";
import ChartComponent from "./ChartComponent";
import Rules from "./Rules";
import OrderBook from "./OrderBook";
import { useParams } from "react-router-dom";
import { CoinsContext } from "../contexts/CoinsContext";
import { useApi } from "../contexts/ApiContext";
import { useAuth } from "../AuthContext";
const EventPanel = ({ isHistory = false }) => {
  const { coin, id } = useParams();
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");
  const [activeTab, setActiveTab] = useState(isHistory ? "Charts" : "Predict"); // Default to 'Predict'
  const [market, setMarket] = useState("");
  const [realTimePrice, setRealTimePrice] = useState(null); // State for real-time price
  const { getCoinData } = useContext(CoinsContext);
  const { apiHost, apiVersion } = useApi();
  const [slug, setSlug] = useState("");
  const { isAuthenticated } = useAuth();

  const tabs = ["Predict", "Charts"]; // Define your tabs

  const fetchMarket = async (timeZone) => {
    const endpoint = isHistory ? "/market/historical-data" : "/market";
    try {
      const response = await fetch(
        `${apiHost}${apiVersion}${endpoint}`,
        {
          method: "POST",
          headers: {
            ...(isHistory && {
              Authorization: `Bearer ${localStorage.getItem('sanctum_token')}`
            }),
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ id }),
          credentials: "include",
        }
      );
      const data = await response.json();
      setMarket(data);
      const newDate = new Date(`${data.end_time}Z`);
      const formattedDate = newDate.toLocaleDateString("en-US", {
        timeZone: timeZone,
        day: "numeric",
        month: "short",
        year: "numeric",
      });
      setDate(formattedDate);
      const formattedTime = newDate.toLocaleTimeString("en-US", {
        timeZone: timeZone,
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      });
      setTime(formattedTime);
    } catch (error) {
      console.error("Error fetching market:", error);
    }
  };

  useEffect(() => {
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    fetchMarket(userTimeZone);
  }, []); // Empty dependency array to run once

  useEffect(() => {
    if(!isHistory){
      const coinData = getCoinData(id);
      if (coinData) {
        setRealTimePrice(coinData.currentPrice); // Update real-time price
        setSlug(coinData.fullCryptoName);
      }
    }
  }, [getCoinData, isHistory, id]); // Empty dependency array to run once

  return (
    <div className="flex flex-col mt-24">
      {/* Mobile Buttons for switching between tabs (hidden on desktop) */}
      {!isHistory && (
      <div className="flex lg:hidden w-full">
        {tabs.map((tab) => (
          <button
            key={tab}
            className={`flex-1 px-4 py-2 font-semibold ${
              activeTab === tab
                ? "bg-gray-600 text-white"
                : "bg-gray-700 text-gray-200"
            }`}
            onClick={() => setActiveTab(tab)}
          >
            {tab}
          </button>
        ))}
      </div>
      )}

      {/* Mobile layout */}
      <div className="flex flex-col lg:hidden">
        {/* Conditional rendering based on activeTab */}
        {activeTab === "Predict" && !isHistory && (
          <div className="w-full">
            <BetPanel
              coin={coin}
              id={market.id}
              min={market.min_size}
              max={market.max_size}
              odds={market.odds}
              targetDate={market.end_time}
              realTimePrice={realTimePrice}
            />
          </div>
        )}
        {activeTab === "Charts" && (
          <div className="w-full">
            {isHistory ? (
              market ? (
                <ChartComponent coin={coin} isHistory={isHistory} start_time={market.start_time} end_time={market.end_time} />
              ) : (
                <div className="flex items-center justify-center">
                  <div
                    className="w-10 h-10 border-4 border-t-transparent border-white rounded-full animate-spin"
                    role="status"
                    aria-label="Loading chart"
                  ></div>
                </div>
              )
            ) : (
              <div className="flex flex-col space-y-4">
              <ChartComponent coin={coin} realTimePrice={realTimePrice} />
              <OrderBook id={market.id} myBets={true} />
              </div>
            )}
          </div>
        )}
        <div className="w-full">
          <Rules coin={coin} slug={slug} date={date} time={time} isHistory={isHistory} />
          <OrderBook id={market.id} isHistory={isHistory} />
        </div>
      </div>

      {/* Desktop layout */}
      <div className="hidden lg:flex w-full">
        {/* Main content area */}
        <div className={`flex flex-col ${isHistory ? `w-full` : `w-3/4`} pr-4`}>
          <div className="mb-4">
            {isHistory ? (
              market ? (
                <ChartComponent coin={coin} isHistory={isHistory} start_time={market.start_time} end_time={market.end_time} />
              ) : (
                <div className="flex items-center justify-center">
                  <div
                    className="w-10 h-10 border-4 border-t-transparent border-white rounded-full animate-spin"
                    role="status"
                    aria-label="Loading chart"
                  ></div>
                </div>
              )
            ) : (
              <div className="flex flex-col space-y-4">
                <ChartComponent coin={coin} realTimePrice={realTimePrice} />
                {isAuthenticated && <OrderBook id={market.id} myBets={true} />}
              </div>
            )}
          </div>
          <div className="mb-4">
            <Rules coin={coin} slug={slug} date={date} time={time} isHistory={isHistory} />
            <OrderBook id={market.id} isHistory={isHistory} />
          </div>
        </div>
        {/* PredictPanel area */}
        {isHistory ? null : (
          <div className="w-1/4">
            <div className="sticky top-20">
              <BetPanel
                coin={coin}
                id={market.id}
                min={market.min_size}
                max={market.max_size}
                odds={market.odds}
                targetDate={market.end_time}
                realTimePrice={realTimePrice}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default EventPanel;
