import React, { useState, useEffect, useContext } from "react";
import "react-range-slider-input/dist/style.css";
import Countdown from "./Countdown";
import { Range } from "react-range";
import { useParams } from "react-router-dom";
import { FaMinus, FaPlus, FaCoins, FaCircleNotch } from "react-icons/fa";
import { RiMoneyDollarCircleFill } from "react-icons/ri";
import { useAuth } from "../AuthContext";
import Confetti from "react-confetti";
import { CoinsContext } from "../contexts/CoinsContext";
import { useApi } from "../contexts/ApiContext";
import LoginForm from "./LoginForm";

const BetPanel = ({
  targetDate,
  id,
  min = 2,
  max = 100,
  odds,
  realTimePrice,
}) => {
  const [price, setPrice] = useState(null);
  const [potentialWinnings, setPotentialWinnings] = useState({
    first: 0,
    second: 0,
    third: 0,
  });
  const {
    balance,
    fetchBalance,
    isUserOnTrialMode,
    trialAttempts,
    setTrialAttempts,
    verifyUserTrial,
    isAuthenticated, // Assume this is provided by useAuth
  } = useAuth();
  const [betAmount, setBetAmount] = useState(50);
  const { coin } = useParams();
  const lowerBound = realTimePrice * 0.7;
  const upperBound = realTimePrice * 1.3;
  const [showMore, setShowMore] = useState(false);
  const [showConfetti, setShowConfetti] = useState(false);
  const [isLastTwoHours, setIsLastTwoHours] = useState(false);
  const [isProcessingOrder, setIsProcessingOrder] = useState(false);
  const [orderSuccess, setOrderSuccess] = useState(false);
  const [orderError, setOrderError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [minValue, setMinValue] = useState(min);
  const { fetchHistoricalData } = useContext(CoinsContext);
  const totalTrial = 3;

  // State for the modal
  const [showModal, setShowModal] = useState(false);
  const [formMode, setFormMode] = useState("login");

  const { apiHost, apiVersion } = useApi();

  // Functions for opening login or register modals
  const toggleModal = () => setShowModal(!showModal);
  const openLoginForm = () => {
    setFormMode("login");
    toggleModal();
  };
  const openRegisterForm = () => {
    setFormMode("register");
    toggleModal();
  };

  useEffect(() => {
    const symbol = coin.toUpperCase().includes("-USD")
      ? coin.toUpperCase()
      : `${coin.toUpperCase()}-USD`;
    const fetchPrice = async () => {
      try {
        const response = await fetch(
          `${apiHost}${apiVersion}/coinbase/product/candle?symbol=${symbol}`
        );
        const rawData = await response.json();
        const data = rawData.map((item) => parseFloat(item.close));
        setPrice(data[data.length - 1]);
      } catch (error) {
        console.error("Error fetching live price:", error);
        setPrice(60000);
      }
    };

    if (coin) {
      fetchPrice();
    }
  }, [coin]);

  const handlePriceChange = (amount) => {
    setErrorMessage("");
    const newPrice = (price !== null ? price : 0) + amount;

    if (newPrice <= lowerBound) {
      setErrorMessage(
        `Your predicted price is currently lower than 30% of the current price (${realTimePrice.toFixed(
          2
        )}).`
      );
    } else if (newPrice >= upperBound) {
      setErrorMessage(
        `Your predicted price is currently higher than 30% of the current price (${realTimePrice.toFixed(
          2
        )}).`
      );
    }

    setPrice(newPrice);
  };

  const handlePriceBlur = () => {
    setErrorMessage("");
    if (price <= lowerBound) {
      setErrorMessage(
        `Your predicted price is currently lower than 30% of the current price (${realTimePrice.toFixed(
          2
        )}).`
      );
    } else if (price >= upperBound) {
      setErrorMessage(
        `Your predicted price is currently higher than 30% of the current price (${realTimePrice.toFixed(
          2
        )}).`
      );
    }
  };

  const handleBetAmountChange = (value) => {
    let numericValue;

    if (Array.isArray(value)) {
      numericValue = value[0];
    } else {
      numericValue = value.toString().replace(/[^0-9]/g, "");
    }

    numericValue = numericValue === "" ? 0 : parseInt(numericValue, 10);

    if (numericValue < minValue) {
      numericValue = minValue;
    } else if (numericValue > max) {
      numericValue = max;
    }

    setBetAmount(numericValue);
  };

  const handleInputBetAmountChange = (value) => {
    let numericValue;

    if (Array.isArray(value)) {
      numericValue = value[0];
    } else {
      numericValue = value.toString().replace(/[^0-9]/g, "");
    }

    numericValue =
      numericValue === "" || !numericValue ? 0 : parseInt(numericValue, 10);

    setBetAmount(numericValue);
  };

  const handleBetSubmit = async () => {
    setErrorMessage("");
    if (!isAuthenticated) {
      openLoginForm();
      return;
    }

    let amount = parseFloat(betAmount);

    if (amount > balance && !isUserOnTrialMode) {
      setErrorMessage("Insufficient balance.");
      return;
    }

    // Validate amount with min
    if (amount < min) {
      setErrorMessage(`Minimum amount is ${min}`);
      return;
    }

    setIsProcessingOrder(true);

    try {
      const token = localStorage.getItem("sanctum_token");
      const response = await fetch(
        `${apiHost}${apiVersion}/order`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            id: id,
            prediction: price,
            amount: parseFloat(amount),
          }),
        }
      );

      if (response.ok) {
        // Start confetti and show success message
        setShowConfetti(true);
        setOrderSuccess(true);
        fetchBalance();

        if (trialAttempts < totalTrial) {
          const newTotalAttempts = trialAttempts + 1;
          setTrialAttempts(newTotalAttempts);
        }

        verifyUserTrial();
      } else {
        setOrderError(true);
      }

      setIsProcessingOrder(false);
    } catch (error) {
      console.error("Error placing bet:", error);
      setOrderError(true);

      setIsProcessingOrder(false);
    }
  };

  useEffect(() => {
    if (odds) {
      setPotentialWinnings({
        first: betAmount * Number(odds.first),
        second: betAmount * Number(odds.second),
        third: betAmount * Number(odds.third),
      });
    }
  }, [betAmount, odds]);

  useEffect(() => {
    if (showConfetti) {
      const timer = setTimeout(() => setShowConfetti(false), 5000); // Confetti disappears after 5 seconds
      return () => clearTimeout(timer);
    }
  }, [showConfetti]);

  // Watch the user trial mode then apply or do not apply the bonus
  useEffect(() => {
      setMinValue(min);
      setBetAmount(50);
  }, []);

  // Add an effect to watch the countdown status
  useEffect(() => {
    const countDownDate = new Date(targetDate + "Z").getTime();
    const interval = setInterval(() => {
      const now = new Date().getTime();
      const distance = countDownDate - now;
      setIsLastTwoHours(distance <= 2 * 60 * 60 * 1000);
    }, 1000);

    return () => clearInterval(interval);
  }, [targetDate]);

  const isDisabled =
    price === null ||
    betAmount === "" ||
    isNaN(parseFloat(betAmount)) ||
    (betAmount >  balance && !isUserOnTrialMode) ||
    isLastTwoHours ||
    isProcessingOrder ||
    (isUserOnTrialMode && trialAttempts >= totalTrial);

  return (
    <div className="relative bg-gradient-to-br from-gray-800 via-gray-900 to-black text-gray-200 shadow-2xl rounded-lg p-6 space-y-6">
      {/* Confetti Effect */}
      {showConfetti && <Confetti />}
      {/* Success Message */}
      {orderSuccess && (
        <div className="absolute px-4 inset-0 bg-gray-700 bg-opacity-75 flex flex-col items-center rounded-lg justify-center z-50">
          <div className="bg-green-600 text-white rounded-lg p-6 space-y-4">
            <h2 className="text-2xl font-bold">Bet Placed Successfully!</h2>
            <p className="text-lg">Your bet has been placed. Good luck!</p>
            <button
              className="w-full py-2 bg-green-700 hover:bg-green-800 rounded-md text-lg font-semibold"
              onClick={() => setOrderSuccess(false)}
            >
              Close
            </button>
          </div>
        </div>
      )}

      {/* Error Message */}
      {orderError && (
        <div className="absolute px-4 inset-0 bg-gray-700 bg-opacity-75 flex flex-col items-center rounded-lg justify-center z-50">
          <div className="bg-red-600 text-white rounded-lg p-6 space-y-4">
            <h2 className="text-2xl font-bold">Error Placing Bet!</h2>
            <p className="text-lg">
              Your bet has not been placed. Please try again.
            </p>
            <button
              className="w-full py-2 bg-red-700 hover:bg-red-800 rounded-md text-lg font-semibold"
              onClick={() => setOrderError(false)}
            >
              Close
            </button>
          </div>
        </div>
      )}

      {/* Modal for Login/Register */}
      {showModal && (
        <LoginForm
          onClose={toggleModal}
          mode={formMode}
          setFormMode={setFormMode}
          apiHost={apiHost}
          apiVersion={apiVersion}
        />
      )}

      <div className="flex items-center space-x-3">
        <FaCoins className="text-yellow-400 text-2xl animate-pulse" />
        <div className="flex flex-col">
          <h3 className="text-xl font-semibold">{coin.toUpperCase()}</h3>
          <div className="flex items-center gap-2">
            <p className="font-semibold text-gray-200">Results: </p>
            {targetDate ? (
              <Countdown
                targetDate={targetDate}
                className="text-green-400"
              />
            ) : (
              <div className="flex justify-center px-4 py-2">
                <FaCircleNotch className="text-lg sm:text-sm font-semibold animate-spin" />
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="relative">
        <label className="block text-sm font-medium mb-1">
          Predicted Price
        </label>
        <div className="flex">
          <button
            className="bg-gray-700 hover:bg-gray-600 text-white rounded-l-md px-3 py-2"
            onClick={() => handlePriceChange(-1)}
          >
            <FaMinus />
          </button>
          <input
            type="number"
            className="w-full text-center bg-gray-800 text-white focus:outline-none"
            value={price !== null ? price : ""}
            onChange={(e) => setPrice(parseFloat(e.target.value))}
            onBlur={handlePriceBlur}
          />
          <button
            className="bg-gray-700 hover:bg-gray-600 text-white rounded-r-md px-3 py-2"
            onClick={() => handlePriceChange(1)}
          >
            <FaPlus />
          </button>
        </div>
      </div>

      <div>
        <label className="block text-sm font-medium mb-1">
          Trade Amount{" "}
          {isUserOnTrialMode ? <>(Trial)</> : <>(${balance} available)</>}
        </label>

        <div className="relative">
          <RiMoneyDollarCircleFill className="absolute left-3 top-3 text-gray-400" />
          <input
            type="text"
            className="w-full pl-10 pr-3 py-2 bg-gray-800 text-white rounded-md focus:outline-none"
            value={betAmount === 0 ? "" : betAmount}
            onChange={(e) => handleInputBetAmountChange(e.target.value)}
          />
        </div>
        <div className="mt-4">
          <Range
            step={1}
            min={minValue}
            max={max}
            values={[betAmount]}
            onChange={(values) => handleBetAmountChange(values[0])}
            renderTrack={({ props, children }) => {
              const percentage =
                betAmount > 0
                  ? ((betAmount - minValue) / (max - minValue)) * 100
                  : 0;
              return (
                <div
                  {...props}
                  style={{
                    ...props.style,
                    height: "10px",
                    background: `linear-gradient(90deg, #ff7f00, #ff00ff, #00ffff, #0000ff ${percentage}%, #1f2937 ${percentage}%)`,
                    borderRadius: "5px",
                  }}
                >
                  {children}
                </div>
              );
            }}
            renderThumb={({ props }) => {
              const { key, ...restProps } = props;
              return (
                <div
                  key={key}
                  {...restProps}
                  className="h-6 w-6 bg-green-500 rounded-full shadow focus:outline-none"
                />
              );
            }}
          />
        </div>
      </div>

      <div className="flex flex-col items-center">
        <p className="text-lg mb-2">To Win</p>
        <div className="flex justify-around items-center py-4 bg-gray-800 rounded-md w-full">
          <div className="text-center">
            <span className="text-2xl">🥈</span>
            <p className="text-sm mt-1">
              2nd: ${potentialWinnings.second.toFixed(2)}
            </p>
          </div>
          <div className="text-center">
            <span className="text-2xl">🥇</span>
            <p className="text-sm mt-1">
              1st: ${potentialWinnings.first.toFixed(2)}
            </p>
          </div>
          <div className="text-center">
            <span className="text-2xl">🥉</span>
            <p className="text-sm mt-1">
              3rd: ${potentialWinnings.third.toFixed(2)}
            </p>
          </div>
        </div>
      </div>

      <div className="text-sm rounded-md">
        <p className="">
          {showMore
            ? "If you did not make it onto the list of winners, you will receive a lesser balance back, depending on how accurate your price prediction was."
            : "If you do not win, you will not lose your funds."}
        </p>
        <button
          onClick={() => setShowMore(!showMore)}
          className="text-blue-500 hover:underline focus:outline-none"
        >
          {showMore ? "See Less" : "See More"}
        </button>
      </div>
      {errorMessage && <p className="text-red-500 text-sm">{errorMessage}</p>}
      <button
        className={`w-full py-3 rounded-md text-lg font-semibold transition-all duration-300 ${
          isDisabled
            ? "bg-gray-600 cursor-not-allowed"
            : "bg-green-500 hover:bg-green-600"
        }`}
        onClick={handleBetSubmit}
        disabled={isDisabled}
      >
        {isProcessingOrder ? (
          <p className="animate-pulse">Loading...</p>
        ) : (
          <>
            Place Order
            <p className="text-sm">
              Potential Win: ${potentialWinnings.first.toFixed(2)}
            </p>
          </>
        )}
      </button>
    </div>
  );
};

export default BetPanel;
